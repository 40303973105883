import { WORKBOOK_DISPLAY } from '@/workbook/workbook.constants';
import _ from 'lodash';
import { NavigateFunction } from 'react-router-dom';
import { errorToast } from '@/utilities/toast.utilities';
import HttpCodes from 'http-status-codes';
import { getStorageSafely } from '@/utilities/storage.utilities';
import { isCanceled } from '@/utilities/http.utilities';
import axios, { AxiosError } from 'axios';

/**
 * Determines if the error originated from axios. For some reason the check for isAxiosError is not always reliable.
 *
 * @param error The error
 */
export function isAxiosError(error: unknown): error is AxiosError {
  return axios.isAxiosError(error) || (error as any)?.name === 'AxiosError';
}

function getFullUrl(path: string) {
  return `${window.location.protocol}//${window.location.host}${path}`;
}

export function getFolderLink(folderId: string, tab?: string, fullUrl = false) {
  const path = `/${folderId}/folder${tab ? `?t=${tab}` : ''}`;
  return fullUrl ? getFullUrl(path) : path;
}

export function getWorkbooksLink(tab?: string) {
  return `/workbooks${tab ? `?t=${tab}` : ''}`;
}

export function getNoWorksheetLink() {
  return '/no-worksheet';
}

export function getNoWorkbookLink() {
  return '/no-workbook';
}

export function getWorksheetLink(
  workbookId: string,
  worksheetId?: string,
  folderId?: string,
  workstepId?: string,
  fullUrl = false,
) {
  const path =
    `${folderId ? `/${folderId}` : ''}` +
    `/workbook/${workbookId}/` +
    `${worksheetId ? `worksheet/${worksheetId}` : ''}` +
    `${workstepId ? `?workstepId=${workstepId}` : ''}`;
  return fullUrl ? getFullUrl(path) : path;
}

export function getParameterWorksheetLink(
  workbookId: string,
  worksheetId: string,
  folderId?: string,
  searchParams?: URLSearchParams,
) {
  return `${getViewWorksheetLink(workbookId, worksheetId, folderId)}${
    searchParams ? `?${searchParams.toString()}` : ''
  }`;
}

export function getViewWorksheetLink(
  workbookId: string,
  worksheetId: string,
  folderId?: string,
  workstepId?: string,
  fullUrl = false,
) {
  const path = `${folderId ? `/${folderId}` : ''}/view/worksheet/${workbookId}/${worksheetId}${
    workstepId ? `?workstepId=${workstepId}` : ''
  }`;
  return fullUrl ? getFullUrl(path) : path;
}

export function getShortViewWorksheetLink(worksheetId: string, fullUrl = false) {
  const path = `/view/${worksheetId}`;
  return fullUrl ? getFullUrl(path) : path;
}

export function getPresentWorksheetLink(workbookId: string, worksheetId: string, fullUrl = false) {
  const path = `/present/worksheet/${workbookId}/${worksheetId}`;
  return fullUrl ? getFullUrl(path) : path;
}

export function getHomeScreenAddOnLink(identifierHash: string) {
  return `/hsa?a=${identifierHash}`;
}

export function getLicenseLink() {
  return `/license`;
}

export function getUnauthorizedLink() {
  return '/unauthorized';
}

export function getLoginLink(returnTo?: string, extraSearchParams?: URLSearchParams) {
  const searchParams = new URLSearchParams(extraSearchParams);

  if (returnTo && returnTo !== 'undefined') {
    searchParams.append('returnTo', returnTo);
  }

  return _.join(_.compact(['/login', searchParams.toString()]), '?');
}

export function navigateToUnauthorizedPage() {
  goTo(getUnauthorizedLink());
}

export function navigateToForbiddenPage() {
  goTo('/forbidden');
}

export function navigateToLoadErrorPage(queryParams = '') {
  goTo(`/load-error${_.isEmpty(queryParams) ? '' : `?${queryParams}`}`);
}

export function navigateToConnectionErrorPage() {
  goTo('/connection-error');
}

export function navigateToHeadlessRenderStandby() {
  goTo('/headless-capture-standby');
}

export function navigateToWorksheetInNewTab(
  workbookId: string,
  worksheetId: string,
  folderId?: string,
  viewMode = false,
) {
  const link = viewMode
    ? getViewWorksheetLink(workbookId, worksheetId, folderId)
    : getWorksheetLink(workbookId, worksheetId, folderId);
  window.open(window.location.origin + link, '_blank');
}

export function getReturnToQueryParamFromUrl(): string {
  const { pathname, search } = window.location;
  return pathname && pathname !== '/load-error'
    ? `returnTo=${encodeURIComponent(pathname + (search ? search : ''))}`
    : '';
}

let path: string;
let previousPath: string;

export function setCurrentPath(pathname: string) {
  previousPath = getCurrentPath();
  path = pathname;
}

export function getPreviousPath() {
  return previousPath;
}

export function getCurrentPath() {
  return path;
}

export function getAbsoluteWorksheetURL(workbookId: string, worksheetId: string) {
  return window.location.origin + getWorksheetLink(workbookId, worksheetId);
}

export function getAbsoluteViewWorksheetURL(
  workbookId: string,
  worksheetId: string,
  folderId?: string,
  workstepId?: string,
) {
  return window.location.origin + getViewWorksheetLink(workbookId, worksheetId, folderId, workstepId);
}

export function getAbsolutePresentationWorksheetURL(workbookId: string, worksheetId: string) {
  return window.location.origin + getPresentWorksheetLink(workbookId, worksheetId);
}

export function getWorkbookDisplayMode(currentPath = window.location.pathname): WORKBOOK_DISPLAY | undefined {
  if (_.includes(currentPath, '/view/')) {
    return WORKBOOK_DISPLAY.VIEW;
  }

  if (_.includes(currentPath, 'present/')) {
    return WORKBOOK_DISPLAY.PRESENT;
  }

  if (_.includes(currentPath, '/workbook/')) {
    return WORKBOOK_DISPLAY.EDIT;
  }
}

export function wasWorksheet() {
  return !_.isNil(getPreviousPath()) && _.includes(getPreviousPath(), '/worksheet/');
}

export function isWorksheet() {
  return _.includes(getCurrentPath(), '/worksheet/');
}

export function isWorkbooks() {
  return _.includes(getCurrentPath(), '/workbooks') || _.includes(getCurrentPath(), '/folder');
}

let fromBuilder = false;

export function setFromBuilder(val: boolean) {
  fromBuilder = val;
}

export function getFromBuilder() {
  return fromBuilder;
}

export function goTo(location: string, replace = false) {
  if (replace) {
    return getNavigate()(location, { replace: true });
  } else {
    return getNavigate()(location);
  }
}

export function goToWithReload(url: string) {
  window.open(url, '_self');
}

let navigateFn: NavigateFunction;

export function setNavigate(navigate: NavigateFunction) {
  navigateFn = navigate;
}

export function getNavigate() {
  if (!navigateFn) {
    throw new Error('The navigate function must be set before it can be used');
  }
  return navigateFn;
}

export function isWorksheetDisplayed(path: string) {
  return _.includes(path, '/worksheet/');
}

export const getWorksheetPath = (
  workbookId: string,
  worksheetId: string,
  currentFolderId?: string,
  workbookDisplay?: WORKBOOK_DISPLAY,
) => {
  if (workbookDisplay === WORKBOOK_DISPLAY.EDIT) {
    return getWorksheetLink(workbookId, worksheetId, currentFolderId);
  }

  if (workbookDisplay === WORKBOOK_DISPLAY.VIEW) {
    return getViewWorksheetLink(workbookId, worksheetId, currentFolderId);
  }

  return getPresentWorksheetLink(workbookId, worksheetId);
};

/**
 * Helper function that checks the type of error and navigates to an error page based on that error. If the error is
 * from an API call that resulted in a FORBIDDEN status then it navigates to the forbidden page. If the error is from
 * NOT_FOUND then it navigates to the not found page. Otherwise, it navigates to the general load error page.
 *
 * @param error - The error response
 * @returns The error
 */
export function navigateToCorrectErrorPage(
  error: unknown,
  routeConstantNotFound?: 'ROUTE_NO_WORKBOOK' | 'ROUTE_NO_WORKSHEET',
): unknown {
  const isUnauthorized = isAxiosError(error) && error.response!.status === HttpCodes.UNAUTHORIZED;
  const isForbidden = isAxiosError(error) && error.response!.status === HttpCodes.FORBIDDEN;
  const isNotFound = isAxiosError(error) && error.response!.status === HttpCodes.NOT_FOUND;
  const isBadRequest = isAxiosError(error) && error.response!.status === HttpCodes.BAD_REQUEST;
  const isNotFoundOrBadRequest = isNotFound || isBadRequest;
  const isRequestCanceled = isAxiosError(error) && isCanceled(error.response);

  // All unauthorized errors should be handled by the auth interceptor
  // A canceled request is always replaced by another more relevant request
  if (isUnauthorized || isRequestCanceled) {
    return error;
  }

  if (isForbidden) {
    isWorksheet() ? navigateToUnauthorizedPage() : navigateToForbiddenPage();
  } else if (isNotFoundOrBadRequest && !_.isNil(routeConstantNotFound)) {
    if (routeConstantNotFound === 'ROUTE_NO_WORKBOOK') {
      goTo(getNoWorkbookLink(), true);
    }

    // This handles the case where a link to a particular workbook or worksheet has been saved/bookmarked, but that
    // worksheet has been deleted (deleted as in DELETE in api, archived worksheets load).
    // This isn't the greatest behavior because the action the user should take isn't very clear and there is no
    // button to take the user back to the workbench. However, it is a little better than having a link
    // that doesn't work and redirects to the homescreen.
    if (routeConstantNotFound === 'ROUTE_NO_WORKSHEET') {
      goTo(getNoWorksheetLink(), true);
    }
  } else {
    navigateToLoadErrorPage(isNotFound ? '' : getReturnToQueryParamFromUrl());
    errorToast({ httpResponseOrError: error });
  }

  return error;
}

/**
 * Returns to a serialized state stored in the current state as returnTo. This is used by the
 * login and load error pages to return to the previous page after the login completes or the load error is
 * resolved.
 */
export function returnToPreviousState() {
  const storedParams = _.attempt(JSON.parse, getStorageSafely().getItem('stateParams'));
  getStorageSafely().removeItem('stateParams');
  const urlParams = new URLSearchParams(window.location.search);
  const returnToLink = urlParams.get('returnTo') ?? storedParams?.returnTo ?? getWorkbooksLink();
  goToWithReload(returnToLink);
}

/**
 * @returns true if the current route points to a workbook
 */
export function isInWorkbookRoute() {
  return !_.isUndefined(getWorkbookDisplayMode());
}
