import React, { useState } from 'react';
import { useRouteError } from 'react-router-dom';
import { Button, Icon } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import { sqStateSynchronizer, sqWorkbenchStore, sqWorkstepsStore } from '@/core/core.stores';
import { previousWorkstepAction } from '@/worksteps/worksteps.actions';
import _ from 'lodash';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { SupportRequestModal } from '@/supportRequest/SupportRequestModal';
import { HeaderLogo } from '@/header/HeaderLogo.atom';
import { Accordion } from 'react-bootstrap';

export const RouteError: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const error = useRouteError() as Error;
  console.error(error);

  const previousAvailable = useFluxPath(sqWorkstepsStore, (store) => !_.isNil(store.previous));
  const hasWorksheetId = useFluxPath(sqWorkbenchStore, (store) => !_.isNil(store.stateParams?.worksheetId));

  const [showSupportRequestModal, setShowSupportRequestModal] = useState(false);
  const [isErrorDetailsOpen, setErrorDetailsOpen] = useState(false);

  return (
    <>
      <div className="flexColumnContainer flexCenter splashScreen fullViewport">
        <header className="positionAbsolute left-0 top-3">
          <HeaderLogo />
        </header>

        <div className="jumbotron flexRowContainer flexCenter maxWidth90Vw">
          <h3>{t('SUPPORT_REQUEST.REASON_OPTIONS.PROBLEM')}</h3>

          <Accordion className="maxWidth100Percent">
            <Accordion.Toggle eventKey="0" onClick={() => setErrorDetailsOpen((open) => !open)}>
              <p className="cursorPointer mb5">
                {t('ROUTE_ERROR.SHOW_DETAILS')}
                <Icon extraClassNames="ml3" icon={isErrorDetailsOpen ? 'fa-chevron-right' : 'fa-chevron-down'} />
              </p>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <pre className="mb-0">{error?.stack}</pre>
            </Accordion.Collapse>
          </Accordion>

          <div className="flexColumnContainer mt15">
            <Button
              onClick={() => window.location.reload()}
              label={
                <>
                  <Icon icon="fc-refresh-off" extraClassNames="mr5" /> {t('ERROR_FALLBACK.RETRY')}
                </>
              }
              extraClassNames="mr10"
            />

            {previousAvailable && hasWorksheetId && (
              <Button
                onClick={async () => {
                  await sqStateSynchronizer.getWorkstepAndRehydrate(() =>
                    previousWorkstepAction(
                      sqWorkbenchStore.stateParams.workbookId,
                      sqWorkbenchStore.stateParams.worksheetId,
                    ),
                  );
                  window.location.reload();
                }}
                label={
                  <>
                    <Icon icon="fa-reply" extraClassNames="mr5" /> {t('WORKSTEPS.PREVIOUS')}
                  </>
                }
                extraClassNames="mr10"
              />
            )}

            <Button
              onClick={() => setShowSupportRequestModal(true)}
              label={
                <>
                  <Icon icon="fa-question-circle" extraClassNames="mr5" /> {t('SUPPORT_REQUEST.GET_HELP')}
                </>
              }
            />
          </div>
        </div>
      </div>

      {showSupportRequestModal && (
        <SupportRequestModal
          onClose={() => setShowSupportRequestModal(false)}
          defaultActiveKey="1"
          details={{
            reason: 'Problem',
            summary: error.message ?? t('SUPPORT_REQUEST.REASON_OPTIONS.PROBLEM'),
            description: error?.stack ?? '',
          }}
        />
      )}
    </>
  );
};
